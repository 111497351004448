import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, Pipe, PipeTransform} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NG_ENTITY_SERVICE_CONFIG} from '@datorama/akita-ng-entity-service';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {AkitaNgRouterStoreModule} from '@datorama/akita-ng-router-store';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule as CDKLayout} from '@angular/cdk/layout';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {TranslocoRootModule} from './transloco/transloco-root.module';
import {Module as ComponentsModule} from './components/module';
import {Module as Authentication} from './authentication/module';
import {MaterialModule} from './material/material.module';
import {Module as LayoutModule} from './layout/module';
import {Module as NotificationModule} from './notifications/module';
import {ContentModule} from './content/module';
import {WebshopModule} from './webshop/module';
import {ProductsModule} from "./products/module";
import {WebshopClientModule} from "./webshop-client/module";
import {FoundationModule} from "./foundation/foundation.module";
import {getBrowserCultureLang, getBrowserLang, TranslocoService} from "@ngneat/transloco";
import {DateAdapter} from "@angular/material/core";
import {ActivatedRoute} from "@angular/router";
import {formatCurrency, getCurrencySymbol, registerLocaleData} from "@angular/common";
import {combineLatest, Observable, of, tap} from 'rxjs';
import {catchError, first, map, switchMap} from "rxjs/operators";
import {Language} from "./webshop/language/state/model";
import {LanguageService} from "./webshop/language/state/service";
import {NgxPhotoswipeModule} from "@fnxone/ngx-photoswipe";
import {NgxInstaModule} from "ngx-insta";
import {
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module
} from "ng-recaptcha";
import {GoogleMapsModule} from "@angular/google-maps";
import {GtagModule} from "angular-gtag";
import {FedexModule} from "./fedex/module";
import {ConfigService} from "./webshop/config/state/service";


function loadLanguages(
  translocoService: TranslocoService,
  languageService: LanguageService,
  dateAdapter: DateAdapter<Date>,
) {
  return function () {
    return languageService.languages$.pipe(first()).pipe(
      switchMap(languages => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('language')) {
          localStorage.setItem('language', params.get('language') as string);
        }

        return of(languages);
        //return of(languages.filter(lang => lang.code === 'hu'));
      }),
      map(languages => {

        const availableLanguages = languages.map(language => {
          return {
            id: language.code,
            label: language.slug
          };
        });
        translocoService.setAvailableLangs(availableLanguages);
        translocoService.config.availableLangs = availableLanguages;

        let defaultLanguage = languages.find(language => language.code === 'en');
        let fallbackLanguage = languages.find(language => language.code === 'hu');

        if (defaultLanguage) {
          translocoService.config.defaultLang = defaultLanguage.code as string;
          translocoService.setDefaultLang(defaultLanguage.code as string);
        }
        if (fallbackLanguage) {
          translocoService.config.fallbackLang = fallbackLanguage.code as string;
          translocoService.setDefaultLang(fallbackLanguage.code as string);
        }

        translocoService.config.reRenderOnLangChange = true;
        translocoService.setAvailableLangs(availableLanguages);

        const language = localStorage.getItem('language');
        if (language) {
          translocoService.setActiveLang(language as string);
          dateAdapter.setLocale(language as string);
        } else {
          const browserLang = languages.find(language => language.code === getBrowserCultureLang())

          if (browserLang) {
            translocoService.setActiveLang(browserLang.code as string);
            dateAdapter.setLocale(browserLang.code as string);
          } else {
            const found = languages.find(language => language.code.substring(0, 2) === getBrowserLang());
            translocoService.setActiveLang(found ? found.code as string : translocoService.getDefaultLang());
            dateAdapter.setLocale(found ? found.code.substring(0, 2) as string : translocoService.getDefaultLang());
          }
        }

        return translocoService.getActiveLang();
      }),
      switchMap(lang => translocoService.load(lang)),
      catchError(err => [])
    ).toPromise();
  }
}

export class LocaleService {
  getLocale(): string | undefined {

    const language = localStorage.getItem('language');

    if (!window.location.pathname.includes('admin')) {
      if (window.location.pathname.split('/').length >= 2) {
        localStorage.setItem('language', window.location.pathname.split('/')[1]);
        return window.location.pathname.split('/')[1];
      }
    }
    if (language) {
      return language;
    }


    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return undefined;
    }

    let browserLang: any = window.navigator['languages'] ? window.navigator['languages'][0] : null;
    // @ts-ignore
    browserLang = browserLang || window.navigator.language || window.navigator['browserLanguage'] || window.navigator['userLanguage'];

    if (browserLang.indexOf('-') !== -1) {
      browserLang = browserLang.split('-')[0];
    }

    if (browserLang.indexOf('_') !== -1) {
      browserLang = browserLang.split('_')[0];
    }

    return browserLang;
  }
}

export function localeIdFactory(localeService: LocaleService) {
  return localeService.getLocale();
}

export function localeInitializer(
  localeId: string,
  languageService: LanguageService) {
  return function () {
    return languageService.languages$.pipe(
      first(),
      switchMap(languages => {
          languages = languages.map(language => {
            if (language.code === 'pt-br') {
              language = new Language(language);
              language.code = 'pt';
            } else if (language.code === 'zh-hant') {
              language = new Language(language);
              language.code = 'zh-Hant';
            }
            return language;
          });
          return combineLatest(languages.map(language => combineLatest([
                import(
                  /* webpackInclude: /(hu|en)\.mjs$/ */
                  /* webpackMode: "lazy-once" */
                  /* webpackChunkName: "i18n-extra" */
                  `@/../node_modules/@angular/common/locales/${language.code}.mjs`),
                import(
                  /* webpackInclude: /(hu|en)\.mjs$/ */
                  /* webpackMode: "lazy-once" */
                  /* webpackChunkName: "i18n-extra" */
                  `@/../node_modules/@angular/common/locales/extra/${language.code}.mjs`),
              ]).pipe(
                tap(imp => {
                  registerLocaleData(imp[0].default, language.code, imp[1].default)
                })
              )
            )
          )
        }
      ),
      catchError(err => {
        console.log(err);
        return [];
      })
    ).toPromise();
  }
}


export function currencyCodeFactory(localeService: LocaleService) {
  return 'HUF';
  return localeService.getLocale() === 'hu' ? 'HUF' : 'EUR';
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    GtagModule.forRoot({trackingId: 'G-EWBH3FWG60', trackPageviews: true}),
    AkitaNgRouterStoreModule,
    BrowserAnimationsModule,
    MaterialModule,
    NotificationModule,
    CDKLayout,
    ComponentsModule,
    ContentModule,
    WebshopModule,
    ProductsModule,
    WebshopClientModule,
    Authentication,
    HttpClientModule,
    HttpClientJsonpModule,
    LayoutModule,
    TranslocoRootModule,
    FoundationModule,
    NgxPhotoswipeModule,
    NgxInstaModule,
    RecaptchaV3Module,
    GoogleMapsModule,
    FedexModule
  ],
  exports: [
  ],
  providers: [
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcmulwgAAAAAIsvocz48sdIQhPJylRSKnanWnak"},
    {provide: RECAPTCHA_SETTINGS, useValue: {siteKey: "6LcmulwgAAAAAIsvocz48sdIQhPJylRSKnanWnak"}},
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: {
        baseUrl: environment.apiUrl
      }
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService, LanguageService, DateAdapter],
      useFactory: loadLanguages
    },
    LocaleService,
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [LocaleService]
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useFactory: currencyCodeFactory,
      deps: [LocaleService]
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: localeInitializer,
      deps: [LOCALE_ID, LanguageService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
